<template>
    <v-dialog v-model="visible" persistent max-width="650px">
        <v-card>
            <v-card-title class="justify-center">
                <v-switch
                    dense
                    inset
                    true-value="ativo"
                    false-value="inativo"
                    hide-details
                    class="pb-2"
                    v-model="form.status"
                    :label="form.status == 'ativo' ? 'Ativo' : 'Inativo'"
                />
                <v-spacer/>
                <span class="text-h5">{{ form.id ? 'Edição' : 'Cadastro' }} de Forma de Pagamento</span>
                <v-spacer/>
                <v-btn icon @click="$emit('close')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text align="center" justify="center" :style="`height: ${$vuetify.breakpoint.height - 250}px;`">
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            label="Descrição"
                            v-model="form.descricao"
                            :filled="isDefault"
                            :readonly="isDefault"
                            outlined
                            dense
                            hide-details
                            class="mt-2"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            label="Acréscimo Valor Fixo (R$)"
                            v-model="form.acrescimo_valor"
                            prefix="R$"
                            placeholder="0.00"
                            outlined
                            dense
                            hide-details
                            v-mask="['#.##','##.##','###.##','####.##','#####.##']">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            label="Acréscimo Percentual (%)"
                            v-model="form.acrescimo_percentual"
                            suffix="%"
                            placeholder="0.00"
                            outlined
                            dense
                            hide-details
                            v-mask="['#.##','##.##','###.##','####.##','#####.##']">
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            label="Desconto Valor Fixo (R$)"
                            v-model="form.desconto_valor"
                            prefix="R$"
                            placeholder="0.00"
                            outlined
                            dense
                            hide-details
                            v-mask="['#.##','##.##','###.##','####.##','#####.##']">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            label="Desconto Percentual (%)"
                            v-model="form.desconto_percentual"
                            suffix="%"
                            placeholder="0.00"
                            outlined
                            dense
                            hide-details
                            v-mask="['#.##','##.##','###.##','####.##','#####.##']">
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row v-if="canPayOnline(form.id)">
                    <v-col cols="12">
                        <v-switch
                            dense
                            inset
                            hide-details
                            class="pb-2"
                            v-model="form.pagamento_online"
                            label="Pagamento online"
                        />
                    </v-col>
                </v-row>
                <v-row v-if="isPixEnabled">
                    <v-col cols="12">
                        <v-text-field
                            label="Chave pix"
                            v-model="form.chave_pix"
                            outlined
                            dense
                            hide-details
                        />
                    </v-col>
                </v-row>
                <v-row v-if="isPixEnabled">
                    <v-col cols="12" sm="6">
                        <v-text-field
                            label="Nome do recebedor pix"
                            v-model="form.nome_pix"
                            outlined
                            dense
                            hide-details
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-select
                            v-model="form.tipo_pix"
                            outlined
                            dense
                            hide-details
                            :items="['Telefone', 'CPF', 'CNPJ', 'E-Mail', 'Aleatória']"
                            label="Tipo da chave pix"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn text color="warning" @click="$emit('close')">Cancelar</v-btn>
                <v-spacer />
                <v-btn color="success" @click="salvar" :loading="loading">
                    <v-icon>mdi-content-save</v-icon>&nbsp;Salvar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { hasModule } from '@/utils/module';

export default {
    name: 'FormaPagamento',

    props: {
        visible: {
            type: Boolean,
            default: false
        },
        formData: {
            type: Object,
            default: Object
        },
    },

    data: () => ({
        loading: false,
        form: {
            ativo: true,
            descricao: '',
            acrescimo_valor: '',
            acrescimo_percentual: '',
            desconto_valor: '',
            desconto_percentual: '',
        },
    }),

    mounted() {
        this.form = this.formData;
    },

    computed: {
        ...mapState([
            'usuario',
        ]),

        isPixEnabled() {
            return this.form.descricao?.toLowerCase()?.includes('pix');
        },

        isDefault() {
            return this.form.id <= 4;
        },

        isSuporteUser() {
            return this.usuario.tipo === 'suporte';
        },
    },

    watch: {
        formData(value) {
            this.form = value ? JSON.parse(JSON.stringify(value)) : {};
        },
    },

    methods: {
        ...mapMutations({
            setDashboardInitialized: 'setDashboardInitialized',
        }),

        salvar() {
            const id = this.form.id;

            if (id) {
                this.loading = true;
                this.$http.put(`formas-pagamento/${id}`, this.form).then(resp => {
                    const data = resp.data;
                    if (data.type == 'warning') {
                        this.notify(data.msg, 'warning');
                        return;
                    }
                    this.$emit('onCadastro');
                    this.setDashboardInitialized(false);
                }).catch(() => {
                    this.notify('Não foi possivel concluir a operação', 'warning');
                }).finally(() => (this.loading = false));
                return;
            }

            this.loading = true;
            this.$http.post('formas-pagamento', this.form).then(() => {
                this.$emit('onCadastro');
                this.notify('Salvo com sucesso!');
                this.setDashboardInitialized(false);
            }).catch(() => {
                this.notify('Não foi possivel concluir a operação', 'warning');
            }).finally(() => (this.loading = false));
        },

        canPayOnline(formaPgto) {
            const PIX = 3;
            const CARTAO_CREDITO = 2;

            const canPayOnline = [PIX, CARTAO_CREDITO].includes(formaPgto);
            const hasOnlinePayment = hasModule('PAGAMENTO_ONLINE');

            return canPayOnline && hasOnlinePayment;
        },
    },
}
</script>

<style scoped>
.v-card {
    padding: 0 15px 0 15px;
}
</style>
